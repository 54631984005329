<template>
    <div class="product-grid">
      <div class="card-item-wrapper hp-mod-card-hover inline" v-for="product in products" :key="product.id">
      <a :href="product.link" target="_blank">
      <div class="card-item" >
    <div class="card-image card-image-background">
      <img class="image" 
           :src="product.image"  
           :alt="product.title">
    </div>
    <div class="card-item-desc">
      <div class="card-segment"></div>
      <div class="card-title">
        <span class="title">{{ product.title }}</span>
      </div>
      <div class="hp-mod-price">
        <div class="hp-mod-price-first-line">
          <span class="currency">{{ product.currency }}</span><span class="price">{{ product.price }}</span>
        </div>
        <div class="hp-mod-price-second-line">
          <span class="hp-mod-price-text align-left">
            <span class="currency">{{ product.currency }}</span><span class="price">{{ product.oldPrice }}</span>
          </span>
          <span class="hp-mod-discount align-left"> {{ product.discount }}</span>
        </div>
      </div>
    </div>
  </div>
</a>
</div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
export default {
  setup() {
    const products = ref([]);
    let page = ref(1);
    const fetchProducts = async () => {
      const response = await axios.get(
        `${apiUrl}/products?page=${page.value}`
      );
      const queryParams = window.location.search;
      products.value = [...products.value, ...response.data.map(product => {
        product.link += queryParams;
        return product;
      })];
      page.value++;
    };

    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        fetchProducts();
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      fetchProducts();
    });

    return {
      products,
    };
  },
};
</script>
  
  <style scoped>
a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
}
.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card-nation,.card-title {
    text-overflow: ellipsis;
    overflow: hidden
}

.card-wrapper {
    width: 100%
}

.card-row {
    margin-bottom: 20px
}

.card-item-wrapper {
    vertical-align: top;
    width: 189px;
    margin-right: 7px;
    min-height: 189px;
    background: #fff;
    height: 100%
}

.card-item-wrapper:nth-child(6n) {
    margin-right: 0
}

.card-pagination {
    height: 0;
    margin: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 3 0%;
    -ms-flex: 0 3 0%;
    flex: 0 3 0%
}

.card-segment {
    color: #a90b26;
    font-size: 12px;
    line-height: 14px;
    min-height: 14px;
    margin: 2px 0
}

.card-segment-icon {
    display: block;
    height: 14px;
    width: 100%;
    font-size: 12px
}

.card-segment-icon .image {
    display: inline-block;
    float: left;
    min-width: 12px;
    height: 12px
}

.card-segment-icon .text {
    display: inline-block;
    float: left;
    font-family: Roboto-Medium;
    line-height: 14px;
    font-size: 12px;
    height: 14px;
    margin-left: 4px
}

.card-li-content {
    height: 100%;
    width: 100%;
    display: block
}

.card-item {
    height: 100%
}

.card-item .hp-mod-price {
    margin-top: 4px
}

.card-item .hp-mod-price-first-line {
    line-height: 22px;
    height: 22px;
    font-size: 18px;
    color: #f57224
}

.card-item .hp-mod-price-second-line {
    margin-top: 4px;
    margin-right: 4px;
    line-height: 14px;
    height: 14px;
    color: #9e9e9e;
    font-size: 12px
}

.card-item .hp-mod-discount {
    margin-right: 4px
}
.hp-mod-price-text {
    text-decoration: line-through
}
.card-item-desc {
    padding: 4px 8px 12px;
    background: #fff
}

.card-title {
    position: relative;
    font-size: 14px;
    height: 36px;
    line-height: 18px;
    color: #212121;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.card-installment-text,.card-nation {
    font-size: 12px;
    line-height: 14px;
    color: #9e9e9e;
    height: 14px
}

.card-title .tagTitle {
    display: block;
    text-indent: 45px
}

.card-price-text {
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through
}

.card-discount {
    margin-left: 4px
}

.card-service-icons {
    height: 16px
}

.card-service-icons .title {
    width: 16px;
    margin-right: 6px;
    margin-top: -2px
}

.card-service-icons .image {
    width: 100%;
    background: initial!important
}

.card-service-image {
    height: 16px;
    width: 16px
}

.card-footer {
    margin-top: 8px;
    height: 14px;
    clear: both
}

.card-installment-text {
    clear: both
}

.card-nation {
    max-width: 74px;
    white-space: nowrap;
    text-align: right;
    float: right
}

.card-nation.no-ratings {
    max-width: 100%;
    width: 100%
}

.card-ratings-comment {
    float: left;
    font-size: 12px;
    color: #9e9e9e
}

.card-image {
    width: 189px;
    height: 189px
}

.card-image .image {
    width: 100%;
    height: 100%
}

.card-image-background {
    box-sizing: border-box;
    line-height: 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #ccd8d8
}

.card-ratings {
    position: relative;
    float: left;
    margin-right: 2px;
    margin-bottom: 3px;
    display: block;
    height: 11px;
    line-height: 11px
}

.card-rating-layer {
    display: inline-block
}

.card-rating-layer.top-layer {
    position: absolute;
    overflow: hidden;
    white-space: nowrap
}

.card-rating-layer .rating {
    font-size: 11px;
    line-height: 11px;
    height: 11px;
    margin-right: -3px
}

.card-rating-layer .rating:last-child {
    margin-right: 0
}

.card-rating-layer .rating:before {
    font-size: 11px;
    color: #dadada
}

.card-rating-layer.checked .rating:before {
    color: #faca51
}

.card-campaign-icon {
    height: 16px;
    position: relative;
    display: inline-block!important;
    top: 0;
    margin-right: 5px
}

.card-load-more {
    padding-top: 20px;
    padding-bottom: 9px;
    clear: both
}

.card-load-more .button {
    font-family: Roboto-Medium;
    margin: 0 auto;
    display: block;
    width: 387px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #1a9cb8;
    border: 1px solid #1a9cb8;
    text-transform: uppercase;
    cursor: pointer
}

.card-loading {
    clear: both;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.card-loading .loading-svg,.card-loading svg {
    display: block;
    margin: 10px auto;
    width: 40px;
    height: 40px
}

.card-loading .circular-loader {
    -webkit-animation: rotate 1.5s linear infinite;
    animation: rotate 1.5s linear infinite;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto
}

.card-loading .loader-path {
    stroke-dasharray: 2200;
    stroke-dashoffset: 2200;
    -webkit-animation: dash 1s ease-in infinite,rotate 1.5s linear infinite forwards;
    animation: dash 1s ease-in infinite,rotate 1.5s linear infinite forwards;
    -webkit-transition: all 1s ease-in;
    transition: all 1s ease-in;
    stroke-linecap: round;
    stroke-width: 16px;
    stroke: #fff;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center
}


</style>
  